import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from 'apollo-upload-client';
import gql from "graphql-tag";
import { v4 as uuidv4 } from "uuid";

(function() {
  "use strict";

  angular.module("EntrakV5").service("Api", ["$http", "$q", "$state", "$rootScope", "Service", "KEY", "APIKEY", "URL", "REFRESH_URL", "IS_LOCAL", api]);

  function api($http, $q, $state, $rootScope, Service, KEY, APIKEY, URL, REFRESH_URL, IS_LOCAL) {
    console.log("api service");

    // graphQL
    var client = null;
    function initApolloClient(signInMethod, idToken, forceInit) {
      // var httpLink = new createHttpLink({
      //   uri: `${URL}api/gql`,
      //   credentials: "include",
      // });

      if (client && !forceInit)
        return;

      var authLink = setContext(function(_, opt) {
        var tmp = {
          headers: {
            ...opt.headers,
            "x-request-id": uuidv4(),
          },
        }
        if (idToken)
          tmp.headers.authorization = "Bearer " + idToken;
        tmp.headers["authorization-method"] = signInMethod;

        return tmp;
      });

      client = new ApolloClient({
        link: authLink.concat(createUploadLink({ uri: `${URL}api/gql`, credentials: 'include' })),
        // link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
      });
    }

    function toApolloQuery(queryStr, ...fragmentStr) {
      return client.query({
        fetchPolicy: "no-cache",
        query: gql("query {" + queryStr + "}" + fragmentStr.join("")),
      });
    }
    function toApolloMutation(mutationStr, ...fragmentStr) {
      return client.mutate({
        mutation: gql("mutation {" + mutationStr + "}" + fragmentStr.join("")),
      });
    }
    function toApolloMutationWithFile(mutationStr, file1, ...fragmentStr){
        return client.mutate({
            variables: {
                file1: file1
            },
            mutation: gql('mutation($file1: Upload!) {' + mutationStr + '}' + fragmentStr.join(""))
        });
    }

    //QueryParam class, new it
    function QueryParam() {
      this.value = "";
    }
    QueryParam.prototype.toSafeStr = function(val) {
      if (typeof val != "string") val += "";
      var safeStr = val.replace(/\\|"/g, "");
      if (safeStr !== val)
        console.warn("unsafe str removed", val);
      return safeStr;
    }
    QueryParam.prototype.val = function(paramName, val) {
      if (paramName) {
        this.value += paramName + ":" + val + " ";
      } else {
        this.value += val + " ";
      }
      return this;
    }
    QueryParam.prototype.str = function(paramName, val) {
      if (val == null) {
        val = "";
      } else {
        val = this.toSafeStr(val);
      }
      if (paramName) {
        this.value += paramName + ':"' + val + '" ';
      } else {
        this.value += '"' + val + '" ';
      }
      return this;
    }
    QueryParam.prototype.enum = function(paramName, val) {
      val = this.toSafeStr(val);
      return this.val(paramName, val);
    }
    QueryParam.prototype.obj = function(paramName, val, objectTypes) {
      //objectTypes: {str:[fieldName], val:[fieldName], enum:[fieldName], arr:[{fieldName, fieldType, objectTypes}], obj:[{fieldName, objectTypes}]}
      if (paramName) {
        paramName = paramName + ":";
      } else {
        paramName = "";
      }

      if (!val) {
        if (val !== undefined) this.value += paramName + "null ";
        return this;
      }

      this.value += paramName + "{ ";
      for (var type in objectTypes) {
        if (objectTypes.hasOwnProperty(type)) {
          var list = objectTypes[type];
          for (var i = 0; i < list.length; i++) {
            var field = list[i];
            if (type === "arr") {
              var arrVal = val[field.fieldName];
              if (arrVal !== undefined) {
                this.arr(field.fieldName, arrVal, field.fieldType, field.objectTypes);
                this.value += ",";
              }
            } else if (type === "obj") {
              var objVal = val[field.fieldName];
              if (objVal !== undefined) {
                this.obj(field.fieldName, objVal, field.objectTypes);
                this.value += ",";
              }
            } else {
              var typeVal = val[field];
              if (typeVal !== undefined) {
                this[type](field, typeVal);
                this.value += ",";
              }
            }
          }
        }
      }
      if (this.value.slice(-1) === ",") this.value = this.value.slice(0, -1);
      this.value += "} ";
      return this;
    }
    //2d array not supported yet
    QueryParam.prototype.arr = function(paramName, val, fieldType, objectTypes) {
      //fieldType: {str, val, enum, obj}, objectTypes: {str:[fieldName], val:[fieldName], enum:[fieldName], obj:[{objectTypes}}
      if (paramName) {
        paramName = paramName + ":";
      } else {
        paramName = "";
      }

      if (!val) {
        this.value += paramName + "[] ";
        return this;
      }

      this.value += paramName + "[ ";
      for (var i = 0; i < val.length; i++) {
        if (i != 0) this.value += ",";
        this[fieldType](null, val[i], objectTypes);
      }
      this.value += "] ";
      return this;
    }
    QueryParam.prototype.get = function() {
      return "(" + this.value.trim() + ")";
    }
    // graphQL

    // interceptor
    //should call bind to this function
    function apolloSuccessInterceptor(key, res) {
      if (res.errors || !res.data) {
        apolloErrorInterceptor(res);
      } else {
        res = res.data;
        return key && res.hasOwnProperty(key) ? res[key] : res;
      }
    }
    function apolloErrorInterceptor(res) {
      // if (res.graphQLErrors && res.graphQLErrors[0] && res.graphQLErrors[0].extensions && res.graphQLErrors[0].extensions.code === APIKEY.unauthenticated){
      // if (res.networkError && res.networkError.result && res.networkError.result.errors && res.networkError.result.errors[0] && res.networkError.result.errors[0].extensions.code === APIKEY.unauthenticated){
      if (res.networkError && (res.networkError.statusCode === 401 || (res.networkError.result && res.networkError.result.statusCode === 401))) {
        window.location = REFRESH_URL + encodeURIComponent(window.location.href);
      } else {
        console.error("api call fail", res);
        throw res;
      }
    }
    // interceptor

    // query
        //account page
        var userListObj = '{ errors users }';

        //fragment
        var ownerFrag = 'fragment Owner on OwnerProfile { id email firstName lastName departmentId }';
        var simpleDepartmentFrag = 'fragment SimpleDepartment on Department { id name }';
        var userPreferenceFrag = 'fragment UserPreference on NotificationPreference { workstationCloseAlert frequentRequestAdminAlert deviceOfflineAlert }'
        var simpleProfileFrag = 'fragment SimpleProfile on Profile { id email firstName lastName }';
        var displayProfileFrag = 'fragment DisplayProfile on Profile { ...SimpleProfile aclRole aclRoles department { ...SimpleDepartment } }' + simpleProfileFrag + simpleDepartmentFrag;
        var fullProfileFrag = 'fragment FullProfile on Profile { ...DisplayProfile passwordExpired managedZones { zoneId } inviteStatusV2 accessCard recentWorkstationId notificationPreference { ...UserPreference } }' + displayProfileFrag + userPreferenceFrag;
        var basicProfileFrag = 'fragment BasicProfile on Profile { ...SimpleProfile aclRole aclRoles departmentId inviteStatusV2 accessCard recentWorkstationId }' + simpleProfileFrag;
        var profileTenantFrag = 'fragment ProfileTenant on Tenant { id name disablePwdSignIn apiKey passwordPolicy maxExtensionHours defaultLanguage allowSelfReg enableInviteVisitor defaultExtensionMinutes viewOthers oauths { provider code } scenes { id name configs { id deviceId } } } ';
        
        //workstation
        var workstationCountFrag = 'fragment WorkstationCount on Workstation { count { aircon light smartlight iaq button offline } }';
        var workstationDelegationFrag = 'fragment WorkstationDelegation on Workstation { delegations { userId } }';
        var workstationDeviceIdsFrag = 'fragment WorkstationDeviceIds on Workstation { deviceIds }';
        var workstationAllStatusFrag = 'fragment WorkstationAllStatus on Workstation { status isCooling isWarming dimmingLevel schedule { nextOnAt nextOffAt withIn } }';
        var workstationStatusFrag = 'fragment WorkstationStatus on Workstation { status schedule { nextOnAt nextOffAt withIn } }';

        var displayWorkstationNoOwnerFrag = 'fragment DisplayWorkstationNoOwner on Workstation { id name zoneId isHotDesk sharable adminOnly owners { id } }';
        var displayWorkstationFrag = 'fragment DisplayWorkstation on Workstation { id name zoneId isHotDesk sharable adminOnly owners { ...Owner } }' + ownerFrag;
        var simpleStatusWorkstationFrag = 'fragment SimpleStatusWorkstation on Workstation { ...DisplayWorkstationNoOwner ...WorkstationStatus }' + displayWorkstationNoOwnerFrag + workstationStatusFrag;
        var detailStatusWorkstationFrag = 'fragment DetailStatusWorkstation on Workstation { ...DisplayWorkstationNoOwner ...WorkstationAllStatus ...WorkstationDeviceIds ...WorkstationCount }' + displayWorkstationNoOwnerFrag + workstationAllStatusFrag + workstationDeviceIdsFrag + workstationCountFrag;
        var fullStatusWorkstationFrag = 'fragment FullStatusWorkstation on Workstation { ...DisplayWorkstation ...WorkstationAllStatus ...WorkstationDeviceIds ...WorkstationCount ...WorkstationDelegation }' + displayWorkstationFrag + workstationAllStatusFrag + workstationDeviceIdsFrag + workstationCountFrag + workstationDelegationFrag;
        //TODOricky replace by above
        var simpleWorkstationFrag = 'fragment SimpleWorkstation on Workstation { ...DisplayWorkstation ...WorkstationDelegation ...WorkstationDeviceIds }' + displayWorkstationFrag + workstationDelegationFrag + workstationDeviceIdsFrag;
        //workstation

        //room
        var roomCountFrag = 'fragment RoomCount on Room { count { aircon light smartlight iaq button offline } }';
        var roomDeviceIdsFrag = 'fragment RoomDeviceIds on Room { deviceIds }';
        var roomAllStatusFrag = 'fragment RoomAllStatus on Room { status isCooling isWarming dimmingLevel schedule { nextOnAt nextOffAt withIn } }';
        var roomStatusFrag = 'fragment RoomStatus on Room { status schedule { nextOnAt nextOffAt withIn } }';

        var displayRoomFrag = 'fragment DisplayRoom on Room { id name zoneId adminOnly }';
        var simpleStatusRoomFrag = 'fragment SimpleStatusRoom on Room { ...DisplayRoom ...RoomStatus }' + displayRoomFrag + roomStatusFrag;
        var detailStatusRoomFrag = 'fragment DetailStatusRoom on Room { ...DisplayRoom ...RoomAllStatus ...RoomDeviceIds ...RoomCount }' + displayRoomFrag + roomAllStatusFrag + roomDeviceIdsFrag + roomCountFrag;
        //TODOricky replace by above
        var simpleRoomFrag = 'fragment SimpleRoom on Room { ...DisplayRoom deviceIds }' +  displayRoomFrag;
        //room

        var simpleDeviceFrag = 'fragment SimpleDevice on Device { id serialId deviceType applicationType dimmable online location { id x y } }';
        var fullDeviceFrag = 'fragment FullDevice on Device { id serialId gatewayId deviceType applicationType dimmable defaultFanSpeed online status location { id x y }}';
		var fullDeviceDefaultInfoFrag = 'fragment FullDeviceDefaultInfo on Device { id serialId defaultValue { defaultTemperature defaultDimming } thermoProfile { heatIndexInSchedule logicInSchedule heatIndexOutSchedule logicOutSchedule } co2Profile { threshold logic } }';
        var floorSimpleDeviceFrag = 'fragment FloorSimpleDevice on Zone { gateways { id devices { ...SimpleDevice } } }' + simpleDeviceFrag;
        // var floorFullDeviceFrag = 'fragment FloorFullDevice on Zone { gateways { id serialId hubId devices { ...FullDevice } } }' + fullDeviceFrag;
		// optimized query
		var floorFullDeviceFrag = 'fragment FloorFullDevice on Zone { devices { ...FullDevice } gateways { id serialId,hubId } }' + fullDeviceFrag;
		var floorFullDeviceDefaultFrag = 'fragment FloorFullDeviceDefault on Zone { devices { ...FullDeviceDefaultInfo }}' + fullDeviceDefaultInfoFrag;
		
		var floorFullDeviceNoneGatewayFrag = 'fragment FloorFullDeviceNoneGateway on Zone {devices { ...FullDevice } gateways { id serialId,hubId } }' + fullDeviceFrag;

        var ctrlConditionObj = "{ index temperatureHigh temperatureLow humidityHigh humidityLow }";
        var profileTenantObj = '{ ...ProfileTenant }';
        var currentUserObj = '{ ...FullProfile tenant { ...ProfileTenant controlConditions ctrlConditionObj serviceCredentials { providerTenantCode } } }'.replace(/ctrlConditionObj/g, ctrlConditionObj);
        var departmentObj = '{ ...SimpleDepartment }';
        var recentUsedObj = '{ recentUsedNodes { nodeId lastUsedTime workstation { ...DetailStatusWorkstation } room { ...DetailStatusRoom } } }';

        var basicProfileObj = '{ ...BasicProfile }';
        var displayProfileObj = '{ ...DisplayProfile }';
        var simpleProfileObj = '{ ...SimpleProfile }';
        var displayTenantObj = '{ id name }';

        var scheduleWsObj = '{ ...DisplayWorkstation timetableId }';
        var locationWsObj = '{ ...SimpleWorkstation locationPolygon }';
        var simpleStatusWsObj = '{ ...SimpleStatusWorkstation }';
        var detailStatusWsObj = '{ ...DetailStatusWorkstation }';
        var myWsObj = '{ ...FullStatusWorkstation timetableId }';
        var availableWsObj = '{ id name isHotDesk }';

        var scheduleRmObj = '{ ...DisplayRoom timetableId zoneId }';
        var locationRmObj = '{ ...SimpleRoom locationPolygon }';
        var simpleStatusRmObj = '{ ...SimpleStatusRoom }';
        var detailStatusRmObj = '{ ...DetailStatusRoom }';

        var fullDeviceObj = '{ ...FullDevice }';
        var userPreferenceObj = '{ ...UserPreference }';

        // var logObjectObj = '{ profile { ...SimpleProfile } }';
        // var logObjectWithCountObj = '{ workstation { ...DisplayWorkstation ...WorkstationCount } room { ...DisplayRoom ...RoomCount } profile { ...SimpleProfile } }';
        var logPageObj = '{ nodeActivitiesLog { nodeId action timestamp triggerBy triggerId remark } }';
        // var logPageWithNodeObj = '{ nodeActivitiesLog { object logObjectObj nodeId action timestamp triggerBy } lastNodeEvents { object logObjectWithCountObj nodeId action timestamp } }'.replace(/logObjectWithCountObj/g, logObjectWithCountObj).replace(/logObjectObj/g, logObjectObj);
        var logPageCsvObj = '{ nodeActivitiesLog { nodeId action timestamp triggerBy triggerId } }';
        var heatmapPageObj = '{ nodeActivitiesLog { nodeId action timestamp triggerBy triggerId } }';
        var temperatureLogObj = '{ id defaultTemperature readings }';

        var sceneConfigObj = '{ id deviceId status dimmingLevel }';
        var sceneObj = '{id zoneId name adminOnly status configs sceneConfigObj lux lightSensorConfig { condition threshold } }'.replace(/sceneConfigObj/g, sceneConfigObj);
        var scenePageObj = '{ id zones { id name floorPlan scenes sceneObj ...FloorSimpleDevice } }'.replace(/sceneObj/g, sceneObj);

        var scheduleObj = '{ id name cityId timeslots { id weekday start { hour minute action } end { hour minute action } } exceptions { id priority calendarId slots { id start { hour minute action } end { hour minute action } } } }';
        // var scheduleNodeObj = '{ ...on WorkstatioeatMode defaultConditionDuration defaultCoolerDelta defaultWarmerDelta ...Floorn scheduleWsObj ...on Room scheduleRmObj }'.replace(/scheduleWsObj/g, scheduleWsObj).replace(/scheduleRmObj/g, scheduleRmObj);
		    var scheduleNodeObj = '{ ...on Workstation scheduleWsObj ...on Room scheduleRmObj }'.replace(/scheduleWsObj/g, scheduleWsObj).replace(/scheduleRmObj/g, scheduleRmObj);
        var schedulePageZoneObj = '{ zones { id name alwaysOn workstations scheduleWsObj rooms scheduleRmObj } }'.replace(/scheduleWsObj/g, scheduleWsObj).replace(/scheduleRmObj/g, scheduleRmObj);

        var floorListObj = '{ id name zones { id name alwaysOn } }';
        var floorAndNodeListObj = '{ id name zones { id name alwaysOn rooms { ...DisplayRoom } workstations { ...DisplayWorkstationNoOwner } } }';
        
		var locationPageObj = '{ id name closeDelay alwaysOn floorPlan heatMode defaultConditionDuration defaultCoolerDelta defaultWarmerDelta workstations locationWsObj rooms locationRmObj ...FloorFullDevice }'.replace(/locationWsObj/g, locationWsObj).replace(/locationRmObj/g, locationRmObj);
		
		var locationDeviceDefaultInfoPageObj = '{ id name closeDelay alwaysOn floorPlan heatMode defaultConditionDuration defaultCoolerDelta defaultWarmerDelta ...FloorFullDeviceDefault }';
		
		var locationIncludeAndDevicesPageObj = '{ id name closeDelay alwaysOn floorPlan heatMode defaultConditionDuration defaultCoolerDelta defaultWarmerDelta ...FloorFullDeviceNoneGateway }'
		
		var pageObjIncludelWorkStationAndRooms = '{ id name workstations locationWsObj rooms locationRmObj }'.replace(/locationWsObj/g, locationWsObj).replace(/locationRmObj/g, locationRmObj);
		
		// Refactor the getZone to get all the locations and devices directly.
        var sensorDataObj = '{ iaqReading { measurement avg } heatIndex { indoor outdoor } lux }';

        var visitorObj = '{ id email startTime endTime accessCode }';

        var delegationObj = '{ userId }';

        var zoneAdminObj = '{ userId zoneId }';

        var cityObj = '{ id country city }';

        var weatherObj = '{ temperature humidity icon timestamp country cityName }';

        var simpleSpecialDayObj = '{ id name }';
        var specialDayObj = '{ id name dates { id timestamp } }';
        var holidayObj = '{ city cityObj holidays { id name timestamp } }'.replace(/cityObj/g, cityObj);

        var extensionObj = '{ id maxExtensionHours defaultExtensionMinutes }';

        var providerTenantObj = '{ tenantId providerTenantCode }';

        var subscriptionObj = '{ id provider resourceCode room { name } expirationTime }';

        var setPointLogObj = '{ deviceId temperature timestamp }';

        var roleOptionObj = "{ roles tenantPortalAccess }";

        var habitapMappingObj = "{ clientId }";
        var habitapFacilityMappingObj = "{ nodeId facility }";

        var energyMeterLogObj = "{ time kwh }";

        var Struct = {
            nodeCondition: {
                val: ["dimmingLevel"],
                enum: ["comfortState"],
            },
            profile: {
                enum: ["role"],
                str: ["departmentId", "accessCard", "firstName", "lastName"],
            },
            profileQuery: {
                str: ["id", "searchKey"],
                val: ["adminOnly", "limit", "offset"],
                enum: ["type"],
            },
            workstationCreate: {
                str: ["name"],
                val: ["adminOnly", "isHotDesk", "sharable"],
                arr: [{
                    fieldName: "locationIds",
                    fieldType: "str"
                }]
            },
            workstationUpdate: {
                str: ["name"],
                val: ["adminOnly", "isHotDesk", "sharable"],
                arr: [{
                    fieldName: "locationIds",
                    fieldType: "str"
                }]
            },
            room: {
                str: ["name"],
                val: ["adminOnly"],
                arr: [{
                    fieldName: "locationIds",
                    fieldType: "str",
                }, {
                    fieldName: "bookingData",
                    fieldType: "obj",
                    objectTypes: {
                      str: ["resourceCode"],
                      enum: ["provider"]
                    }
                }]
            },
            zoneInfo: {
                val: ["defaultConditionDuration", "defaultCoolerDelta", "defaultWarmerDelta", "heatMode", "alwaysOn", "closeDelay"]
            },
            deviceInfo: {
                val: ["defaultTemperature", "defaultDimming", "heatIndexInSchedule", "heatIndexOutSchedule", "co2Threshold"],
                str: ["logicInSchedule", "logicOutSchedule", "co2Logic"],
                enum: ["defaultFanSpeed"]
            },
            timeTable: {
                str: ["name", "cityId"],
                arr: [{
                    fieldName: "timeslots",
                    fieldType: "obj",
                    objectTypes: {
                        str: ["id"],
                        val: ["delete"],
                        enum: ["weekday"],
                        obj: [{
                            fieldName: "start",
                            objectTypes: {
                                val: ["hour", "minute"],
                                enum: ["action"],
                            }
                        }, {
                            fieldName: "end",
                            objectTypes: {
                                val: ["hour", "minute"],
                                enum: ["action"],
                            }
                        }]
                    }
                }, {
                    fieldName: "exceptions",
                    fieldType: "obj",
                    objectTypes: {
                        str: ["id", "calendarId"],
                        val: ["priority", "delete"],
                        arr: [{
                            fieldName: "slots",
                            fieldType: "obj",
                            objectTypes: {
                                str: ["id"],
                                val: ["delete"],
                                obj: [{
                                    fieldName: "start",
                                    objectTypes: {
                                        val: ["hour", "minute"],
                                        enum: ["action"],
                                    }
                                }, {
                                    fieldName: "end",
                                    objectTypes: {
                                        val: ["hour", "minute"],
                                        enum: ["action"],
                                    }
                                }]
                            }
                        }],
                    }
                }]
            },
            controlCondtion: {
              val: ["index", "priority", "temperatureLow", "temperatureHigh", "humidityLow", "humidityHigh"]
            },
            timeTableNodeInput: {
                str: ["id"],
                val: ["nodeType"]
            },
            specialDayCreate: {
                arr: [{
                    fieldName: "dates",
                    fieldType: "val",
                }],
                str: ["name"],
            },
            specialDayUpdate: {
                arr: [{
                    fieldName: "add",
                    fieldType: "val",
                }, {
                    fieldName: "remove",
                    fieldType: "str",
                }],
                str: ["name"],
            },
            tenant: {
                str: ["name", "dns"],
                val: ["maxExtensionHours", "defaultExtensionMinutes"],
            },
            temperatureLogInput: {
              str: ["zoneId"],
              val: ["startTime", "endTime"],
              enum: ["mode"],
            },
            setPointInput: {
              val: ["startTime", "endTime"],
              str: ["deviceId"]
            }
        }
    // query

    return {
      initApolloClient: initApolloClient,

      // need to wrap apollo api call by $q or $apply, otherwise angularjs 2-way binding may not work
      createApiCaller: function() {
        return {
          // need to wrap apollo api call by this function(or $q, $apply), otherwise angularjs 2-way binding may not work
          call: function(promises, skipActiveCheck) {
            var isArr = Array.isArray(promises);
            var that = this;
            return $q.all(isArr ? promises : [promises]).then(function(resList) {
                if (that._isActive || skipActiveCheck) {
                  return isArr ? resList : resList[0];
                } else {
                  return $q.reject(KEY.ignore);
                }
            }, function(err) {
                return $q.reject(that._isActive || skipActiveCheck ? err : KEY.ignore);
            });
          },
          cancel: function() {
            this._isActive = false;
          },
          _isActive: true,
        }
      },

      logout: function() {
        if (IS_LOCAL) {
          Service.storageDelete("et_method");
          Service.storageDelete("et_it");
          Service.storageDelete("et_rt");
        } else {
          Service.deleteCookie("et_method");
          Service.deleteCookie("et_it");
          Service.deleteCookie("et_rt");
        }
        window.location = URL + 'auth/signout';
      },

      refreshTenantApiKey: function() {
        var queryName = "refreshTenantApiKey";
        var str = queryName;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      setProviderTenantId: function(providerId){
          var queryName = 'setProviderTenantId';
          var selector = new QueryParam().str("providerTenantCode", providerId).enum("provider", APIKEY.provider.microsoft);
          var str = queryName + selector.get() + providerTenantObj;
          return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getTenantHabitapMapping: function(tenantId){
          var queryName = "getTenantMapping";
          var selector = new QueryParam().str("tenantId", tenantId);
          var str = queryName + selector.get() + habitapMappingObj;
          return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      createTenantHabitapMapping: function(tenantId, clientId){
          var queryName = 'createTenantMapping';
          var selector = new QueryParam().str("tenantId", tenantId).str("clientId", clientId);
          var str = queryName + selector.get() + habitapMappingObj;
          return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      updateTenantHabitapMapping: function(tenantId, clientId){
          var queryName = 'updateTenantMapping';
          var selector = new QueryParam().str("tenantId", tenantId).str("clientId", clientId);
          var str = queryName + selector.get() + habitapMappingObj;
          return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      deleteTenantHabitapMapping: function(tenantId){
          var queryName = 'deleteTenantMapping';
          var selector = new QueryParam().str("tenantId", tenantId);
          var str = queryName + selector.get();
          return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getHabitapFacilityMappings: function(zoneId){
          var queryName = "listNodeMapping";
          var selector = new QueryParam().str("zoneId", zoneId).val("limit", 99999);
          var str = queryName + selector.get() + habitapFacilityMappingObj;
          return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      createHabitapFacilityMapping: function(nodeId, facilityId){
          var queryName = 'createNodeMapping';
          var selector = new QueryParam().str("nodeId", nodeId).str("facility", facilityId);
          var str = queryName + selector.get() + habitapFacilityMappingObj;
          return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      updateHabitapFacilityMapping: function(nodeId, facilityId){
          var queryName = 'updateNodeMapping';
          var selector = new QueryParam().str("nodeId", nodeId).str("facility", facilityId);
          var str = queryName + selector.get() + habitapFacilityMappingObj;
          return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      deleteHabitapFacilityMapping: function(nodeId){
          var queryName = 'deleteNodeMapping';
          var selector = new QueryParam().str("nodeId", nodeId);
          var str = queryName + selector.get();
          return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      updatePwdPolicy: function(policy){
          var queryName = 'updatePasswordPolicy';
          var selector = new QueryParam().enum("passwordPolicy", policy);
          var str = queryName + selector.get() + profileTenantObj;
          return toApolloMutation(str, profileTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      updateDisablePwd: function(id, disabled){
          var queryName = 'setTenantDisableSignPwd';
          var selector = new QueryParam().str("tenantId", id).val("disabled", disabled);
          var str = queryName + selector.get() + profileTenantObj;
          return toApolloMutation(str, profileTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      updateDefaultLanguage: function(id, lang) {
          var queryName = 'updateTenantDefaultLanguage';
          var selector = new QueryParam().str("tenantId", id).val("defaultLanguage", lang);
          var str = queryName + selector.get() + profileTenantObj;
          return toApolloMutation(str, profileTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      updateExtension: function(id, name, maxExtendHrs, defaultExtendMins){
          var queryName = 'updateTenant';
          var selector = new QueryParam().str("id", id).obj("tenant", {
              name: name,
              maxExtensionHours: maxExtendHrs,
              defaultExtensionMinutes: defaultExtendMins
          }, Struct.tenant);
          var str = queryName + selector.get() + extensionObj;//tenantObj; dont get any field here since it return all floors of the tenant even though they under different landlord, call reload
          return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      addOAuthBinding: function(tenantId, code, provider) {
        var queryName = "addOAuthBinding";
        var selector = new QueryParam().str("id", tenantId).str("code", code).enum("provider", provider);
        var str = queryName + selector.get() + profileTenantObj;
        return toApolloMutation(str, profileTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      removeOAuthBinding: function(tenantId, code) {
        var queryName = "removeOAuthBinding";
        var selector = new QueryParam().str("id", tenantId).str("code", code);
        var str = queryName + selector.get() + profileTenantObj;
        return toApolloMutation(str, profileTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getSubscriptions: function(){
        var queryName = "getSubscriptions";
        var str = queryName + subscriptionObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      deleteSubscription: function(id){
        var queryName = "deleteSubscription";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get();
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      setAllowSelfRegistration: function(allow){
        var queryName = "setAllowSelfRegistration";
        var selector = new QueryParam().val("allowSelfReg", allow);
        var str = queryName + selector.get() + profileTenantObj;
        return toApolloMutation(str, profileTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      setViewOthers: function(id, allow){
        var queryName = "updateViewOtherWorkstation";
        var selector = new QueryParam().str("id", id).val("viewOther", allow);
        var str = queryName + selector.get() + profileTenantObj;
        return toApolloMutation(str, profileTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      setEnableVisitor: function(enable){
        var queryName = "updateInviteVisitor";
        var selector = new QueryParam().val("invite", enable);
        var str = queryName + selector.get() + profileTenantObj;
        return toApolloMutation(str, profileTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getWeather: function(cityId){
        var queryName = "getCurrentWeather";
        var selector = new QueryParam().str("cityId", cityId);
        var str = queryName + selector.get() + weatherObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getTenants: function(){
        var queryName = "getTenants";
        var str = queryName + displayTenantObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      changeProfileTenant: function(id){
        var queryName = "updateTenantProfile";
        var selector = new QueryParam().str("tenantId", id);
        var str = queryName + selector.get();
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getSetRoleOptions: function(){//TODOricky replace by new one
        var queryName = "getSetRoleOptions";
        var str = queryName + roleOptionObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      getCurrentUser: function() {
        var queryName = "myProfile";
        var str = queryName + currentUserObj;
        return toApolloQuery(str, fullProfileFrag, profileTenantFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      getRecentUsed: function() {
        var queryName = "myProfile";
        var str = queryName + recentUsedObj;
        return toApolloQuery(str, detailStatusWorkstationFrag, detailStatusRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      // updateCurrentUserPwd: function(pwd) {
      //   var queryName = "updatePassword";
      //   var selector = new QueryParam().str("password", pwd);
      //   var str = queryName + selector.get();
      //   return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      // },
      getProfiles: function(tenantId, detailLv, limit, offset, searchText) {// 0,1,2
        var queryName = "getProfiles";
        var selector = new QueryParam().obj("query", {
          id: tenantId,
          type: "TENANT",
          limit: limit,
          offset: offset,
          searchKey: searchText
        }, Struct.profileQuery);
        if (detailLv == 2) {
          var str = queryName + selector.get() + basicProfileObj;
          return toApolloQuery(str, basicProfileFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
        } else if (detailLv == 1) {
          var str = queryName + selector.get() + displayProfileObj;
          return toApolloQuery(str, displayProfileFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
        } else {
          var str = queryName + selector.get() + simpleProfileObj;
          return toApolloQuery(str, simpleProfileFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
        }
      },
      getProfile: function(id) {
        var queryName = "getProfile";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + simpleProfileObj;
        return toApolloQuery(str, simpleProfileFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      createProfile: function(tenantId, email, role, departmentId, accessCard, inviteNow) {
        var queryName = "createTenantUserV2";
        var selector = new QueryParam().str("tenantId", tenantId).str("email", email).enum("role", role).obj("options", {
          departmentId: departmentId,
          accessCard: accessCard,
        }, Struct.profile).val("invite", inviteNow);
        var str = queryName + selector.get() + basicProfileObj;
        return toApolloMutation(str, basicProfileFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      updateProfile: function(id, firstName, lastName, role, departmentId, accessCard) {
        var queryName = "updateProfile";
        var selector = new QueryParam().str("id", id).obj("profile", {
          role: role,
          departmentId: departmentId,
          accessCard: accessCard,
          firstName: firstName,
          lastName: lastName,
        }, Struct.profile);
        var str = queryName + selector.get() + basicProfileObj;
        return toApolloMutation(str, basicProfileFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      deleteProfile: function(id) {
        var queryName = "deleteProfile";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get();
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      inviteUser: function(id) {
        var queryName = "inviteUserV2";
        var selector = new QueryParam().str("id", id).str("page", "landlord");
        var str = queryName + selector.get() + "{ inviteStatusV2 }";
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      updateUserPreference: function(hasScheduleOffAlert, hasFrequentRequestAlert, hasDeviceOfflineAlert){
        var queryName = "updateNotificationPreference";
        var selector = new QueryParam().val("workstationCloseAlert", hasScheduleOffAlert).val("frequentRequestAdminAlert", hasFrequentRequestAlert).val("deviceOfflineAlert", hasDeviceOfflineAlert);
        var str = queryName + selector.get() + userPreferenceObj;
        return toApolloMutation(str, userPreferenceFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getDepartments: function(tenantId) {
        var queryName = "getDepartments";
        var selector = new QueryParam().str("tenantId", tenantId);
        var str = queryName + selector.get() + departmentObj;
        return toApolloQuery(str, simpleDepartmentFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      createDepartment: function(tenantId, name) {
        var queryName = "createDepartment";
        var selector = new QueryParam().str("tenantId", tenantId).str("name", name);
        var str = queryName + selector.get() + departmentObj;
        return toApolloMutation(str, simpleDepartmentFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      updateDepartment: function(id, name) {
        var queryName = "updateDepartment";
        var selector = new QueryParam().str("id", id).str("name", name);
        var str = queryName + selector.get() + departmentObj;
        return toApolloMutation(str, simpleDepartmentFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      deleteDepartment: function(id) {
        var queryName = "deleteDepartment";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get();
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getScenePage: function(id) {
        var queryName = "getTenant";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + scenePageObj;
        return toApolloQuery(str, floorSimpleDeviceFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      createScene: function(zoneId, name) {
        var queryName = "createScene";
        var selector = new QueryParam().str("zoneId", zoneId).str("name", name).val("adminOnly", true);
        var str = queryName + selector.get() + sceneObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      updateScene: function(id, name, threshold, logic) {
        var queryName = "updateScene";
        var selector = new QueryParam().str("id", id).str("name", name).str("condition", logic);
        if (threshold != null && threshold != "") {
            selector.val("threshold", threshold);
        }
        var str = queryName + selector.get() + sceneObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      deleteScene: function(id) {
        var queryName = "deleteScene";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + "{ id }";
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      activateScene: function(id) {
        var queryName = "activateScene";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + sceneObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      suspendScene: function(id) {
        var queryName = "suspendScene";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + sceneObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      createConfig: function(sceneId, deviceId, status, dimLv) {
        var queryName = "createConfig";
        var selector = new QueryParam().str("sceneId", sceneId).str("deviceId", deviceId).val("status", status);
        if (dimLv != null)
          selector.val("dimmingLevel", dimLv);
        var str = queryName + selector.get() + sceneConfigObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      updateConfig: function(id, status, dimLv) {
        var queryName = "updateConfig";
        var selector = new QueryParam().str("id", id).val("status", status);
        if (dimLv != null)
          selector.val("dimmingLevel", dimLv);
        var str = queryName + selector.get() + sceneConfigObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      deleteConfig: function(id) {
        var queryName = "deleteConfig";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + "{ id }";
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      updateHeatIndexDefinition: function(configArr) {
        var queryName = "setHeatIndexs";
        var selector = new QueryParam().arr("conditions", configArr, "obj", Struct.controlCondtion);
        var str = queryName + selector.get() + ctrlConditionObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getCurrentWorkstation: function() {
        var queryName = "myWorkstation";
        var str = queryName + myWsObj;
        return toApolloQuery(str, fullStatusWorkstationFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getAvailableWorkstations: function() {
        var queryName = "availableWorkstations";
        var str = queryName + availableWsObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      getControllableWorkstations: function() {
        var queryName = "controllableWorkstations";
        var str = queryName + simpleStatusWsObj;
        return toApolloQuery(str, simpleStatusWorkstationFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      getWorkstation: function(id) {
        var queryName = "getWorkstation";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + detailStatusWsObj;
        return toApolloQuery(str, detailStatusWorkstationFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getAllRooms: function() {
        var queryName = "availableRooms";
        var str = queryName + simpleStatusRmObj;
        return toApolloQuery(str, simpleStatusRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      getRoom: function(id) {
        var queryName = "getRoom";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + detailStatusRmObj;
        return toApolloQuery(str, detailStatusRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      selectWorkstation: function(id) {
        var queryName = "selectWorkstation";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + myWsObj;
        return toApolloMutation(str, fullStatusWorkstationFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      releaseWorkstation: function() {
        var queryName = "releaseWorkstation";
        var str = queryName;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      createWorkstation: function(zoneId, defaultName, locIds, adminOnly, isHotDesk, sharable) {
        var queryName = "createWorkstation";
        var selector = new QueryParam().str("zoneId", zoneId).obj("workstation", {
          name: defaultName,
          locationIds: locIds,
          adminOnly: adminOnly,
          isHotDesk: isHotDesk,
          sharable: sharable
        }, Struct.workstationCreate);
        var str = queryName + selector.get() + locationWsObj;
        return toApolloMutation(str, simpleWorkstationFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      updateWorkstation: function(id, defaultName, locIds, adminOnly, isHotDesk, sharable) {
        var queryName = "updateWorkstation";
        var selector = new QueryParam().str("id", id).obj("workstation", {
          name: defaultName,
          locationIds: locIds,
          adminOnly: adminOnly,
          isHotDesk: isHotDesk,
          sharable: sharable
        }, Struct.workstationUpdate);
        var str = queryName + selector.get() + locationWsObj;
        return toApolloMutation(str, simpleWorkstationFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      deleteWorkstation: function(id) {
        var queryName = "deleteWorkstation";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get();
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      updateWorkstationLocation: function(id, polygonStr) {
        var queryName = "updateWorkstationLocation";
        var selector = new QueryParam().str("id", id).str("locationPolygon", polygonStr);
        var str = queryName + selector.get() + locationWsObj;
        return toApolloMutation(str, simpleWorkstationFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      createRoom: function(zoneId, name, locIds, adminOnly) {
        var queryName = "createRoom";
        var selector = new QueryParam().str("zoneId", zoneId).obj("room", {
          name: name,
          locationIds: locIds,
          adminOnly: adminOnly
        }, Struct.room);
        var str = queryName + selector.get() + locationRmObj;
        return toApolloMutation(str, simpleRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      updateRoom: function(id, name, locIds, adminOnly) {
        var queryName = "updateRoom";
        var selector = new QueryParam().str("id", id).obj("room", {
          name: name,
          locationIds: locIds,
          adminOnly: adminOnly,
        }, Struct.room);
        var str = queryName + selector.get() + locationRmObj;
        return toApolloMutation(str, simpleRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      deleteRoom: function(id) {
        var queryName = "deleteRoom";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get();
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      updateRoomLocation: function(id, polygonStr) {
        var queryName = "updateRoomLocation";
        var selector = new QueryParam().str("id", id).str("locationPolygon", polygonStr);
        var str = queryName + selector.get() + locationRmObj;
        return toApolloMutation(str, simpleRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      
      assignWorkstationOwner: function(wsId, userId){
        var queryName = "selectWorkstationByProfileId";
        var selector = new QueryParam().str("workstationId", wsId).str("profileId", userId);
        var str = queryName + selector.get() + "{ id }";
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      releaseWorkstationOwner: function(wsId, userId){
        var queryName = "releaseWorkstationByProfileId";
        var selector = new QueryParam().str("workstationId", wsId).str("profileId", userId);
        var str = queryName + selector.get();
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      turnOnWorkstation: function(id, duration) {
        var queryName = "initiateWorkstation";
        var selector = new QueryParam().str("id", id);
        if (duration != null)
          selector.val("duration", duration);
        var str = queryName + selector.get() + detailStatusWsObj; //no need schedule?
        return toApolloMutation(str, detailStatusWorkstationFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      turnOffWorkstation: function(id) {
        var queryName = "closeWorkstation";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + detailStatusWsObj; //no need schedule?
        return toApolloMutation(str, detailStatusWorkstationFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      extendWorkstation: function(id, duration) {
        var queryName = "extendWorkstation";
        var selector = new QueryParam().str("id", id).val("duration", duration);
        var str = queryName + selector.get() + detailStatusWsObj; //no need schedule?
        return toApolloMutation(str, detailStatusWorkstationFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      changeWorkstationCondition: function(id, airconStatus, dimLv) {
        var queryName = "changeWorkstationCondition";
        var selector = new QueryParam().str("id", id).obj("condition", {
          dimmingLevel: dimLv == null ? undefined : dimLv,
          comfortState: airconStatus == null ? undefined : airconStatus,
        }, Struct.nodeCondition);
        var str = queryName + selector.get() + detailStatusWsObj; //no need schedule?
        return toApolloMutation(str, detailStatusWorkstationFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      turnOnRoom: function(id, duration) {
        var queryName = "initiateRoom";
        var selector = new QueryParam().str("id", id);
        if (duration != null)
          selector.val("duration", duration);
        var str = queryName + selector.get() + detailStatusRmObj;
        return toApolloMutation(str, detailStatusRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      turnOffRoom: function(id) {
        var queryName = "closeRoom";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + detailStatusRmObj;
        return toApolloMutation(str, detailStatusRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      extendRoom: function(id, duration) {
        var queryName = "extendRoom";
        var selector = new QueryParam().str("id", id).val("duration", duration);
        var str = queryName + selector.get() + detailStatusRmObj;
        return toApolloMutation(str, detailStatusRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      changeRoomCondition: function(id, airconStatus, dimLv) {
        var queryName = "changeRoomCondition";
        var selector = new QueryParam().str("id", id).obj("condition", {
          dimmingLevel: dimLv == null ? undefined : dimLv,
          comfortState: airconStatus == null ? undefined : airconStatus,
        }, Struct.nodeCondition);
        var str = queryName + selector.get() + detailStatusRmObj;
        return toApolloMutation(str, detailStatusRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      inviteVisitor: function(email, startTime, endTime){
        var queryName = "invitationVisitor";
        var selector = new QueryParam().str("email", email).val("startTime", startTime).val("endTime", endTime);
        var str = queryName + selector.get() + visitorObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      revokeVisitor: function(id){
        var queryName = "revokeVisitorInvitation";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + "{ id }";
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      getVisitors: function(endTime) {
        var queryName = "visitorList";
        var selector = new QueryParam().val("endTime", endTime);
        var str = queryName + selector.get() + visitorObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      createDelegation: function(wsId, userId){
        var queryName = "createDelegation";
        var selector = new QueryParam().str("nodeId", wsId).str("userId", userId);
        var str = queryName + selector.get() + delegationObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      deleteDelegation: function(wsId, userId){
        var queryName = "deleteDelegation";
        var selector = new QueryParam().str("nodeId", wsId).str("userId", userId);
        var str = queryName + selector.get() + delegationObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getFloorList: function(tenantId, withNode) {
        var queryName = "getTenant";
        var selector = new QueryParam().str("id", tenantId);
        if (withNode) {
          var str = queryName + selector.get() + floorAndNodeListObj;
          return toApolloQuery(str, displayWorkstationNoOwnerFrag, displayRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
        } else {
          var str = queryName + selector.get() + floorListObj;
          return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
        }
      },
	  // HeatMap Page：devices ,workstations and rooms,cancel gateways
	  getLocationHeatMapPage: function(zoneId) {
		  var queryName = "getZone";
		  var selector = new QueryParam().str("id", zoneId);
		  var str = queryName + selector.get() + locationPageObj;
		  return toApolloQuery(str, floorFullDeviceFrag, simpleWorkstationFrag, simpleRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
	  },
	  
      getLocationPage: function(zoneId) {
        var queryName = "getZone";
        var selector = new QueryParam().str("id", zoneId);
        var str = queryName + selector.get() + locationIncludeAndDevicesPageObj;
		return  toApolloQuery(str, floorFullDeviceNoneGatewayFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
	  // get device default info
	  getLocationDeviceDefaultInfoPage: function(zoneId) {
	    var queryName = "getZone";
	    var selector = new QueryParam().str("id", zoneId);
	    var str = queryName + selector.get() + locationDeviceDefaultInfoPageObj;
	  		return  toApolloQuery(str, floorFullDeviceDefaultFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
	  },
	  
	  getLocationWorkStationAndRooms: function(zoneId) {
	    var queryName = "getZone";
	    var selector = new QueryParam().str("id", zoneId);
	    var str = queryName + selector.get() + pageObjIncludelWorkStationAndRooms;
	    return toApolloQuery(str, simpleWorkstationFrag, simpleRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
	  },
	  
      getAssignedZoneAdmins: function(zoneId){
        var queryName = "queryZoneAdmin";
        if (zoneId){
          var selector = new QueryParam().str("zoneId", zoneId);
          var str = queryName + selector.get() + zoneAdminObj;
        } else {
          var str = queryName + zoneAdminObj;
        }
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      assignZoneAdmin: function(userId, zoneId){
        var queryName = "createZoneAdmin";
        var selector = new QueryParam().str("userId", userId).str("zoneId", zoneId);
        var str = queryName + selector.get() + zoneAdminObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      unassignZoneAdmin: function(userId, zoneId){
        var queryName = "deleteZoneAdmin";
        var selector = new QueryParam().str("userId", userId).str("zoneId", zoneId);
        var str = queryName + selector.get() + zoneAdminObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      scanGateways: function(hubId){
          var queryName = 'scanGateways';
          var selector = new QueryParam().str("hubId", hubId).val("listOnly", false);
          var str = queryName + selector.get() + "{ success }";
          return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      scanDevices: function(gatewayId){
          var queryName = 'scanDevices';
          var selector = new QueryParam().str("gatewayId", gatewayId).val("listOnly", true);
          var str = queryName + selector.get() + "{ success }";
          return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getSensorData: function(id, forWorkstation){
        var queryName = "getEnvironmental";
        var selector = new QueryParam().str("id", id).enum("filter", (forWorkstation ? APIKEY.nodeType.workstation : "DEVICE"));
        var str = queryName + selector.get() + sensorDataObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getEnergyMeterLog: function(povId, startTime, endTime){//device serial is povId
          var queryName = "summaryKwh";
          var selector = new QueryParam().str("povId", povId).enum("summaryIntervalType", "SUMMARY_DAY").val("startTime", startTime).val("endTime", endTime);
          var str = queryName + selector.get() + energyMeterLogObj;
          return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      updateFloorInfo: function(id, defaultConditionDuration, defaultCoolerDelta, defaultWarmerDelta, heatMode, alwaysOn, offDelay) {
        var queryName = "updateZone";
        var selector = new QueryParam().str("id", id).obj("zone", {
          defaultConditionDuration: defaultConditionDuration,
          defaultCoolerDelta: defaultCoolerDelta,
          defaultWarmerDelta: defaultWarmerDelta,
          heatMode: heatMode,
          alwaysOn: alwaysOn,
          closeDelay: offDelay
        }, Struct.zoneInfo);
		var str = queryName + selector.get() + locationIncludeAndDevicesPageObj;
		return toApolloMutation(str, floorFullDeviceNoneGatewayFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      turnAllOnZone: function(zoneId, isOn) {
        var queryName = "toggleZoneDevices";
        var selector = new QueryParam().str("id", zoneId).enum("state", isOn ? APIKEY.action.on : APIKEY.action.off);
        var str = queryName + selector.get() + locationIncludeAndDevicesPageObj;
		return toApolloMutation(str, floorFullDeviceNoneGatewayFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
	  },

      updateDeviceInfo: function(id, defaultTemperature, defaultDimming, defaultFanSpeed, inLogic, outLogic, inIndex, outIndex, co2Logic, co2Threshold, zoneId) {
        var queryName = "portalUpdateDevice";
        var selector = new QueryParam().str("id", id).obj("device", {
            defaultTemperature: defaultTemperature,
            defaultDimming: defaultDimming,
            heatIndexInSchedule: inIndex,
            heatIndexOutSchedule: outIndex,
            logicInSchedule: inLogic,
            logicOutSchedule: outLogic,
            co2Logic: co2Logic,
            co2Threshold: co2Threshold,
            defaultFanSpeed: defaultFanSpeed
        }, Struct.deviceInfo);
        if (zoneId)
          selector.str("zoneId", zoneId);
        var str = queryName + selector.get() + fullDeviceObj;
        return toApolloMutation(str, fullDeviceFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getTenantCities: function() {
        var queryName = "availableCities";
        var str = queryName + cityObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getSchedule: function(id) {
        var queryName = "getTimetable";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + scheduleObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      getSchedules: function() {
        var queryName = "getTimetables";
        var str = queryName + scheduleObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getSchedulePageNodes: function(id) {
        var queryName = "getTenant";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + schedulePageZoneObj;
        return toApolloQuery(str, displayWorkstationFrag, displayRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      createSchedule: function(name, cityId, timeslots, exceptions) {
        var queryName = "createTimetable";
        var paramObj = {
          name: name,
          timeslots: timeslots,
          cityId: cityId,
        }
        if (exceptions && exceptions.length)
          paramObj.exceptions = exceptions;
        var selector = new QueryParam().obj("timetable", paramObj, Struct.timeTable);
        var str = queryName + selector.get() + scheduleObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      updateSchedule: function(id, name, cityId, timeslots, exceptions) {
        var queryName = "updateTimetable";
        var paramObj = {
          name: name,
          timeslots: timeslots,
          cityId: cityId,
        }
        if (exceptions && exceptions.length)
          paramObj.exceptions = exceptions;
        var selector = new QueryParam().str("id", id).obj("timetable", paramObj, Struct.timeTable);
        var str = queryName + selector.get() + scheduleObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      deleteSchedule: function(id) {
        var queryName = "deleteTimetable";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get();
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      addNodesToSchedule: function(id, nodeInputs) {
        var queryName = "addTimetableNodes";
        var selector = new QueryParam().str("id", id).arr("nodes", nodeInputs, "obj", Struct.timeTableNodeInput);
        var str = queryName + selector.get() + scheduleNodeObj;
        return toApolloMutation(str, displayWorkstationFrag, displayRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      moveScheduleNode: function(id, nodeId) {
        var queryName = "moveTimetableNode";
        var selector = new QueryParam().str("id", id).str("nodeId", nodeId);
        var str = queryName + selector.get() + scheduleNodeObj;
        return toApolloMutation(str, displayWorkstationFrag, displayRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      removeNodeFromSchedule: function(id) {
        var queryName = "removeTimetableNode";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + scheduleNodeObj;
        return toApolloMutation(str, displayWorkstationFrag, displayRoomFrag).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getHolidays: function(year) {
        var queryName = "getHolidays";
        var selector = new QueryParam().val("year", year);
        var str = queryName + selector.get() + holidayObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getSpecialDay: function(id) {
        var queryName = "getCalendar";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get() + specialDayObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getSpecialDays: function() {
        var queryName = "getCalendars";
        var str = queryName + simpleSpecialDayObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      createSpecialDay: function(name, dates) {
        var queryName = "createCalendar";
        var selector = new QueryParam().obj("calendar", {
          name: name,
          dates: dates,
        }, Struct.specialDayCreate);
        var str = queryName + selector.get() + simpleSpecialDayObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      updateSpecialDay: function(id, name, addedDates, removedDateIds) {
        var queryName = "updateCalendar";
        var selector = new QueryParam().str("id", id).obj("input", {
          name: name,
          add: addedDates,
          remove: removedDateIds,
        }, Struct.specialDayUpdate);
        var str = queryName + selector.get() + simpleSpecialDayObj;
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      deleteSpecialDay: function(id) {
        var queryName = "deleteCalendar";
        var selector = new QueryParam().str("id", id);
        var str = queryName + selector.get();
        return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getNodeAircons: function(){
        var queryName = "getDevicesByTenant";
        var selector = new QueryParam().str("applicationType", APIKEY.applicationType.aircon);
        var str = queryName + selector.get() + "{ nodeId serials }";
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      getLogPageDataByNodes: function(startTime, endTime, wsIds, rmIds){
        var queryName = "getActivitiesByNodes";
        var selector = new QueryParam().val("startTime", startTime).val("endTime", endTime).arr("workstationIds", wsIds, "str").arr("roomIds", rmIds, "str");
        var str = queryName + selector.get() + logPageObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      getLogPageCsvData: function(startTime, endTime, zoneId){
        var queryName = "getActivitiesLog";
        var selector = new QueryParam().val("startTime", startTime).val("endTime", endTime).str("zoneId", zoneId);
        var str = queryName + selector.get() + logPageCsvObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      getHeatmapPageData: function(startTime, endTime, zoneId){
        var queryName = "getActivitiesLog";
        var selector = new QueryParam().val("startTime", startTime).val("endTime", endTime).str("zoneId", zoneId);
        var str = queryName + selector.get() + heatmapPageObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },
      getTemperatureLog: function(zoneId, startTime, endTime, isDaily){
        var queryName = "getTemperatureLog";
        var selector = new QueryParam().obj("input", {
          zoneId: zoneId,
          mode: (isDaily ? "DAILY" : "HOURLY"),
          startTime: startTime,
          endTime: endTime
        }, Struct.temperatureLogInput);
        var str = queryName + selector.get() + temperatureLogObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      getSetPointLog: function(deviceId, startTime, endTime){
        var queryName = "getSetpointEvents";
        var selector = new QueryParam().obj("input", {
          deviceId: deviceId,
          startTime: startTime,
          endTime: endTime
        }, Struct.setPointInput);
        var str = queryName + selector.get() + setPointLogObj;
        return toApolloQuery(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

      uploadAccounts: function(tenantId, file){
          var queryName = 'profileBatchUpload';
          var selector = new QueryParam().str("tenantId", tenantId).val("filepath", "$file1");
          var str = queryName + selector.get();
          return toApolloMutationWithFile(str, file).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
      },

    }
  }
})();